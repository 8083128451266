// Code generated by Slice Machine. DO NOT EDIT.
// @ts-nocheck

import {defineAsyncComponent} from 'vue';
import {defineSliceZoneComponents} from '@prismicio/vue';

export const components = defineSliceZoneComponents({
    call_to_action_slice: defineAsyncComponent(() => import('./CallToActionSlice/index.vue')),
    faq_slice: defineAsyncComponent(() => import('./FaqSlice/index.vue')),
});
